import { render, staticRenderFns } from "./CustomerPaymentHistory.vue?vue&type=template&id=39a84526&scoped=true"
import script from "./CustomerPaymentHistory.vue?vue&type=script&lang=js"
export * from "./CustomerPaymentHistory.vue?vue&type=script&lang=js"
import style0 from "./CustomerPaymentHistory.vue?vue&type=style&index=0&id=39a84526&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a84526",
  null
  
)

export default component.exports